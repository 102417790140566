<template>
  <div
    class="flex lg:hidden fixed bottom-4 left-0 z-top w-full px-2 pt-1 pb-3 justify-center transition-all duration-700"
    :class="{
      'translate-y-full opacity-0': !showBar
    }"
  >
    <Button
      v-if="settings.siteParts"
      class="w-[160px] !bg-white"
      variant="secondary"
      variant-type="normal"
      :isExternal="false"
      :target="settings.siteParts.general?.secondaryMobileButton?.isExternal ? '_blank' : ''"
      :to="secondaryMobileButtonUrl"
      >{{ settings.siteParts.general?.secondaryMobileButton?.label }}
    </Button>
    <Button
      v-if="settings.siteParts"
      class="w-[160px] ml-2"
      :to="bookingButtonUrl"
      :isExternal="settings.siteParts.general?.bookingEngineLink?.isExternal || false"
      :target="settings.siteParts.general?.bookingEngineLink?.isExternal ? '_blank' : ''"
      variant="primary"
      variantType="normal"
      >{{ settings.siteParts.general?.bookingEngineLink?.label }}
    </Button>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';

const { settings } = storeToRefs(useSettingsStore());
const { $fullpath } = useSiteSettings();

const bookingButtonUrl = computed(() => {
  return settings.value.siteParts?.general?.bookingEngineLink?.isExternal
    ? settings.value.siteParts?.general?.bookingEngineLink?.externalLink
    : $fullpath(settings.value.siteParts?.general?.bookingEngineLink.link._ref || '');
});

const secondaryMobileButtonUrl = computed(() => {
  return settings.value.siteParts?.general?.secondaryMobileButton?.isExternal
    ? settings.value.siteParts?.general?.secondaryMobileButton?.externalLink
    : $fullpath(settings.value.siteParts?.general?.secondaryMobileButton.link._ref || '');
});

const isScrollingDown = ref<boolean>(false);
const isScrollingUp = ref<boolean>(true);
const showBar = ref<boolean>(false);
const lastScrollYPosition = ref<number>(0);
// A scroll will increase Y nearly +- 20px.
// Even when setting "no-scroll" to html element, will cause a page scroll.
// To prevent the header bar from going away at the minimum scroll or when opening menu,
// we set this tollerance that will help to increase the UX;
const tollerance = ref<number>(20);

// create a method that on the onMounted lifecycle hook it add a listener to the scroll action
// to hide or show the button bar depending on the scroll direction

onMounted(() => {
  const handleScroll = () => {
    // if reached the bottom of the page, then we hide the button bar
    if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
      showBar.value = false;
      return;
    }

    // if scrolling down, then we show the button bar
    if (window.scrollY > lastScrollYPosition.value + tollerance.value) {
      isScrollingDown.value = true;
      isScrollingUp.value = false;
      showBar.value = true;
    }

    // if scrolling up, then we hide the button bar
    if (window.scrollY < lastScrollYPosition.value) {
      isScrollingDown.value = false;
      isScrollingUp.value = true;
      showBar.value = false;
    }

    lastScrollYPosition.value = window.scrollY;
  };

  window.addEventListener('scroll', handleScroll);
});
</script>
